$env: "prod";
@charset "UTF-8";

/* リセット用
----------------------------------------------------------------- */
@import './reset';

/* 基本設定用
----------------------------------------------------------------- */
@import './setting';
@import './base';

/* ヘッター
----------------------------------------------------------------- */
@import './header';

/* フッター
----------------------------------------------------------------- */
@import './footer';

/* フォントアイコン
----------------------------------------------------------------- */
@import './modules/webfont';

/* モジュール レイアウト
----------------------------------------------------------------- */
@import './modules/layout';

/* モジュール 見出し
----------------------------------------------------------------- */
@import './modules/heading';

/* モジュール テキスト
----------------------------------------------------------------- */
@import './modules/text';

/* モジュール ボタン
----------------------------------------------------------------- */
@import './modules/btn';

/* モジュール フォーム
----------------------------------------------------------------- */
@import './modules/form';

/* モジュール テーブル
----------------------------------------------------------------- */
@import './modules/table';

/* モジュール その他
----------------------------------------------------------------- */
@import './modules/others';

/* トップページ用
----------------------------------------------------------------- */
@import './top/setting';
@import './top/area-link-list';
@import './top/balloon';
@import './top/banner-link';
@import './top/banner-list';
@import './top/card';
@import './top/carousel';
@import './top/container';
@import './top/content';
@import './top/dashboard';
@import './top/dot-arrow-link';
@import './top/event';
@import './top/feature';
@import './top/gnavi';
@import './top/header';
@import './top/headline';
@import './top/hero';
@import './top/history-recommend';
@import './top/icon-link';
@import './top/include-error';
@import './top/menu-list';
@import './top/mysearch';
@import './top/nav-group';
@import './top/nav-item';
@import './top/new-arrival';
@import './top/no-mysearch';
@import './top/notice';
@import './top/nowhaw';
@import './top/popup';
@import './top/reapproach';
@import './top/related-site';
@import './top/search';
@import './top/search-condition';
@import './top/search-panel';
@import './top/section';
@import './top/share-button';
@import './top/sub-header';
@import './top/sub-menu-list';
@import './top/tag';
@import './top/toggle-switch';
@import './top/wrapper';

/* モジュール ラベル
----------------------------------------------------------------- */
@import './modules/label';

/* モジュール リスト
----------------------------------------------------------------- */
@import './modules/list';

/* モジュール ブロック
----------------------------------------------------------------- */
@import './modules/block';

/* モジュール カセット
----------------------------------------------------------------- */
@import './modules/cassette';

/* js用
----------------------------------------------------------------- */
@import './modules/function';

/* 職種大分類用
----------------------------------------------------------------- */
@import './selectJob/setting';
@import './selectJob/agent-banner';
@import './selectJob/bordered-list';
@import './selectJob/check-box';
@import './selectJob/color-box';
@import './selectJob/content-list';
@import './selectJob/feature';
@import './selectJob/list';
@import './selectJob/list-sub';
@import './selectJob/media';
@import './selectJob/navi-group';
@import './selectJob/search-button';
@import './selectJob/search-list';
@import './selectJob/tag';
@import './selectJob/relation-area';
@import './selectJob/temporary';

/* 気になるページ用
----------------------------------------------------------------- */
@import './bookmark/container';
@import './bookmark/cassetteJobOffer';
@import './bookmark/operation';
@import './bookmark/matchingJob';
@import './bookmark/requestMessage';
@import './bookmark/scoutDetailInfo';

/* 求職者・AGSスカウト
----------------------------------------------------------------- */
@import './scoutAgs/setting';
@import './scoutAgs/scoutSetting';
@import './scoutAgs/agsForm';
@import './scoutAgs/scoutBenefit';
@import './scoutAgs/scoutMailBox';
@import './scoutAgs/scoutDetailInfo';
@import './scoutAgs/scoutReApproach';
@import './scoutAgs/scoutSection';
@import './scoutAgs/agsHistory';
@import './scoutAgs/partsArea';
@import './scoutAgs/btnArea';
@import './scoutAgs/scoutTop';
@import './scoutAgs/requestList';
@import './scoutAgs/fixedArea';
@import './scoutAgs/pullDown';
@import './scoutAgs/scoutAlert';
@import './scoutAgs/scoutCard';
@import './scoutAgs/agsLabel';

/* 求人情報用
----------------------------------------------------------------- */
@import './jobInfo/preview';
@import './jobInfo/jobInfo';
@import './jobInfo/container';
@import './jobInfo/cassetteOfferRecapitulate';
@import './jobInfo/alertAddition';
@import './jobInfo/save-midway';

/* イベント用
----------------------------------------------------------------- */
@import './event/eventTop';
@import './event/eventDetail';
@import './event/eventPreview';
@import './event/eventTopPreview';

/* 企業メッセージ、AGSメッセージ用
----------------------------------------------------------------- */
@import './agsMessage/style';

/* パスワード強度チェック
----------------------------------------------------------------- */
@import './passwordStrengthCheck';

/* 企業用
----------------------------------------------------------------- */
@import './companyInfo/style';

/* ＋Stories.
----------------------------------------------------------------- */
@import './plst/style';

/* 企業一覧
----------------------------------------------------------------- */
@import './companiesList';

/* 企業情報
----------------------------------------------------------------- */
@import './companyInfo';

/* インフォマティブデータ提供モーダル
----------------------------------------------------------------- */
@import './infoConsent';
